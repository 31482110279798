exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-api-introduction-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/docs/api/introduction.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-api-introduction-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-api-routes-balance-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/docs/api/routes/balance.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-api-routes-balance-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-getting-started-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/docs/getting-started.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-getting-started-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-services-escrow-introduction-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/docs/services/escrow/introduction.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-services-escrow-introduction-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-services-escrow-start-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/docs/services/escrow/start.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-services-escrow-start-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-services-payments-introduction-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/src/docs/services/payments/introduction.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-docs-services-payments-introduction-mdx" */)
}

